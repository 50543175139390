import React from "react";
import '../scss/tabContent.scss'
import Accordian from './Accordian'

const Career = () => {
    return (
        <div className="SecondTab">
             <div className="contentbox">
             <Accordian />
             </div>
      
            {/* <div className="contentbox">
                <p>Aug 2022 - Feb 2023</p>
                <p>Frontend Developper（tutor as well）/ Woniu Institute, China</p>
                <li>Build furniture website</li>
                <li>Build film cinema</li>
                <li>Build examination website</li>
                <li>Build menu WeChat small program</li>
                <li>Build user management system website</li>
                <li>
                    Assist lecturer to deal student’s problems and correct their assesments
                </li>
            </div>
            <div className="contentbox">
                <p>Feb 2021 - Jun 2022</p>
                <p>Manager Assistant/ CityMini Market, Dunedin</p>
                <li>Inventory management and inventory accounting</li>
                <li>Responsible for transferring goods between stores</li>
                <li>Communicate with supplier for replenishment</li>
                <li>Monitored cash intake and deposit records, increasing accuracy, and reducing discrepancies</li>
                <li>Other work as required</li>
            </div>
            <div className="contentbox">
                <p>May 2019 - Oct 2019</p>
                <p>Media Editor / Education Summer Camp, China</p>
                <li>Designing format and editing contents</li>
            </div>
            <div className="contentbox">
                <p>Jan 2018 - Mar 2019</p>
                <p>Archivist / China Tower, China</p>
                <li>Evaluating, selecting, retrieving and arranging materials</li>
                <li>Storing and preserving perishable documents</li>
                <li>Identifying and bidding for funding</li>
                <li>Supervising staff and budgets</li>
            </div> */}
            {/* <div className="flexBox">
                <div className="colBox word-rotate">
                    <div className="content">
                        <div className="front">
                            <p>Aug 2022 - Feb 2023</p>
                            <p>Frontend Developper（tutor as well）/ Woniu Institute, China</p>
                        </div>

                        <div className="back">
                            <li>Build furniture website</li>
                            <li>Build film cinema</li>
                            <li>Build examination website</li>
                            <li>Build menu WeChat small program</li>
                            <li>Build user management system website</li>
                            <li>
                                Assist lecturer to deal student’s problems and correct their assesments
                            </li>
                        </div>
                    </div>
                </div>
                <div className="colBox word-rotate">
                    <div className="content">
                        <div className="front">
                            <p>Feb 2021 - Jun 2022</p>
                            <p>Manager Assistant/ CityMini Market, Dunedin</p>
                        </div>
                        <div className="back">
                            <li>Inventory management and inventory accounting</li>
                            <li>Responsible for transferring goods between stores</li>
                            <li>Communicate with supplier for replenishment</li>
                            <li>Monitored cash intake and deposit records, increasing accuracy, and reducing discrepancies</li>
                            <li>Other work as required</li>
                        </div>
                    </div>

                </div>
                <div className="colBox word-rotate">
                    <div className="content">
                        <div className="front">
                            <p>May 2019 - Oct 2019</p>
                            <p>Media Editor / Education Summer Camp, China</p>
                        </div>
                        <div className="back">
                            <li>Designing format and editing contents</li>
                        </div>
                    </div>
                </div>
                <div className="colBox word-rotate">
                    <div className="colBox">
                        <div className="content">
                            <div className="front">
                                <p>Jan 2018 - Mar 2019</p>
                                <p>Archivist / China Tower, China</p>
                            </div>
                            <div className="back">
                                <li>Evaluating, selecting, retrieving and arranging materials</li>
                                <li>Storing and preserving perishable documents</li>
                                <li>Identifying and bidding for funding</li>
                                <li>Supervising staff and budgets</li>
                            </div>
                        </div>
                    </div>


                </div>
            </div> */}
        </div>
    );
};
export default Career;
