import React from "react";
import '../scss/tabContent.scss'

const Eduacation = () => {
    return (
        <div className="FirstTab">
            <div className="row word-rotate">
                <p>
                2020 – 2023</p>
               <p> Otago Polytechnic, Dunedin
                </p>
                <p>
                    Bachelor of Information Technology
                </p>
            </div>
            <div className="row word-rotate">
                <p>
                2019 – 2020</p>
               <p> Otago Polytechnic, Auckland</p>
                <p>
                    NZCEL Level4 endorsed with excellence
                </p>
            </div>
            <div className="row word-rotate">
                <p>
                2016 – 2019 </p>
               <p> Sichuan Post and Telecommunication College, China</p>
               
                <p>
                    Digital Media Application Technology
                </p>
            </div>
        </div>
    );
};
export default Eduacation;