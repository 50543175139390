import React, { useState, useEffect } from 'react';
import '../scss/home.scss'
import github from '../assets/images/github.png'
import linkin from '../assets/images/linkin.png'
import { Link } from 'react-router-dom';
import { useTypingText } from './useTypingText';
import ellen from '../assets/images/ellen.PNG';


export default function Home() {

    const { word } = useTypingText(
        [
            "I'm a big fan of coding",
            "Good at UI and UX",
            "Love to be a frontend developer",
            "Try to be a full stack"
        ],
        130,
        20
    );

    return (
        <div className='container'>
            <div className="content">
                <div className='leftBox'>
                    <div className="image-box word-rotate">
                        <img src={ellen} alt='ellen'/>
                    </div>

                    <div className='iconBox word-rotate'>
                        <Link to='https://www.linkedin.com/in/ellen-chen-79a514232' alt='linkin' target='_blank'>
                            <img src={linkin} alt='linkin' />
                        </Link>

                        <Link to='https://github.com/Ellen259' alt='ins' target='_blank'>
                            <img src={github} alt='github' />
                        </Link>
                    </div>
                </div>

                <div className='rightBox'>
                    {/* <div className='title'> */}
                        <h1 className='word-rotate'>Kia Ora</h1>
                    {/* </div> */}
                    <h2 className='word-rotate'>{word}</h2>
                    <div className='div-slow'>
                        <p>As a graduate with a Bachelor's degree in Information Technology, I am seeking a position as a web developer to utilize my skills and passion for coding to create visually appealing and user-friendly websites. </p>
                        {/* <p>Although I may not have extensive professional experience in the field, </p> */}
                        <p>I possess a strong ability to learn quickly and have developed a solid foundation of knowledge through my academic studies and personal projects. I am committed to keeping up with the latest technologies and tools in the industry and am excited to apply my skills and knowledge to real-world projects. </p>
                        {/* <p>Furthermore, I do not mind any position for this job, internship, part-time or full-time.</p> */}
                    </div>
                </div>
            </div>
        </div>

    )
}
