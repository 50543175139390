import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../scss/accordian.css'

export default function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div>
                        <div>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                March 2023 - Current
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{ color: 'text.secondary', fontSize: '20px', fontWeight: 'bold' }}>Front Staff</Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ fontSize: '20px' }}>
                        <p>Worked at Impression Manor Restaurant, Dunedin</p>
                        <li>Restaurant Service includes the role of busser, food runner, cashier, server</li>
                        <li>Kind of IT Support like fixing laptop problems if has</li>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div>
                        <div>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                Aug 2022 - Feb 2023
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{ color: 'text.secondary', fontSize: '20px', fontWeight: 'bold' }}>Frontend Developper（tutor as well）</Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ fontSize: '20px' }}>
                        <p>Worked at Woniu Institute, China</p>
                        <li>Build furniture website</li>
                        <li>Build film cinema</li>
                        <li>Build examination website</li>
                        <li>Build menu WeChat small program</li>
                        <li>Build user management system website</li>
                        <li>
                            Assist lecturer to deal student’s problems and correct their assesments
                        </li>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <div>
                        <div>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Feb 2021 - Jun 2022</Typography>
                        </div>
                        <div>
                            <Typography sx={{ color: 'text.secondary', fontSize: '20px', fontWeight: 'bold' }}>
                                Manager Assistant
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ fontSize: '20px' }}>
                        <p>Worked at CityMini Market, Dunedin</p>
                        <li>Inventory management and inventory accounting</li>
                        <li>Responsible for transferring goods between stores</li>
                        <li>Communicate with supplier for replenishment</li>
                        <li>Monitored cash intake and deposit records, increasing accuracy, and reducing discrepancies</li>
                        <li>Other work as required</li>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <div>
                        <div>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                May 2019 - Oct 2019
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{ color: 'text.secondary', fontSize: '20px', fontWeight: 'bold' }}>
                                Media Editor
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ fontSize: '20px' }}>
                        <p>Worked at Education Summer Camp, China</p>
                        <li>Designing format and editing contents</li>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <div>
                        <div>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Jan 2018 - Mar 2019</Typography>
                        </div>
                        <div>
                            <Typography sx={{ color: 'text.secondary', fontSize: '20px', fontWeight: 'bold' }}>
                                Archivist
                            </Typography>
                        </div>

                    </div>

                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ fontSize: '20px' }}>
                        <p>Worked at China Tower, China</p>
                        <li>Evaluating, selecting, retrieving and arranging materials</li>
                        <li>Storing and preserving perishable documents</li>
                        <li>Identifying and bidding for funding</li>
                        <li>Supervising staff and budgets</li>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}


