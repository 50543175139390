import React, { useState } from 'react';
import '../scss/header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

// export default function Header ({ isDarkMode, toggleMode }) {
export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  // const [isDarkMode, setIsDarkMode] = useState(false);
  // const toggleMode = () => setIsDarkMode(!isDarkMode);
  const [activeTab, setActiveTab] = useState('');

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    // <div className={`header ${isDarkMode ? 'dark-mode' : ''}`}>
    <div className='header'>

      {/* <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} onClick={toggleMode} /> */}
      <div>
      <FontAwesomeIcon icon={faMoon} />
      <Link to="/" className="logo">Ellen Chen</Link>
      </div>
      <div className="header-right">
        <Link className={activeTab === 'home' ? 'active' : ''} to="/" onClick={() => handleClick('home')}>Home</Link>
        <Link className={activeTab === 'about' ? 'active' : ''} to="/about" onClick={() => handleClick('about')}>About</Link>
        <Link className={activeTab === 'project' ? 'active' : ''} to="/project" onClick={() => handleClick('project')}>Project</Link>
        <Link className={activeTab === 'contact' ? 'active' : ''} to="/contact" onClick={() => handleClick('contact')}>Contact</Link>
      </div>

      <div className="dropdown">
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleDropdown}
          className="dropdownToggle"
        />
        {isOpen && (
          <ul className="dropdownMenu">
            <li className="dropdownItem">
              <Link to='/' onClick={toggleDropdown}>
                Home
              </Link>
            </li>
            <li className="dropdownItem">
              <Link to='/about' onClick={toggleDropdown}>
                About
              </Link>
            </li>
            <li className="dropdownItem">
              <Link to='/project' onClick={toggleDropdown}>
                Project
              </Link>
            </li>
            <li className="dropdownItem">
              <Link to='/contact' onClick={toggleDropdown}>
                Contact
              </Link>
            </li>

          </ul>
        )}
      </div>
    </div >


  )
}
