import React, { useState, useEffect } from 'react';
import '../scss/header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../scss/home.scss'

export default function Contact() {
    const [isShaking, setIsShaking] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsShaking(true);

            setTimeout(() => {
                setIsShaking(false);
            }, 500);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='container bg'>
            <div className="content">
            <div className={`contactBox ${isShaking ? 'shake' : ''}`}>
                    <h1>Contact Me</h1>
                </div>
                <div className='contactBox'>
                    <FontAwesomeIcon icon={faPhone} className='iconBox' />
                    <h3>0212998146</h3></div>
                <div className='contactBox'>
                    <FontAwesomeIcon icon={faEnvelope} className='iconBox' />
                    <h3>1065162442@qq.com</h3>
                </div>
            </div>
        </div>
    )
}
