import React, { useState } from "react";
import Eduacation from "./Education";
import Career from "./Career";
import Strength from "./Strength";
import Interest from "./Interest";
import '../scss/tabs.scss'

const Tabs = () => {
    const [activeTab, setActiveTab] = useState("tab1");
    const handleTab1 = () => {
        setActiveTab("tab1");
    };
    const handleTab2 = () => {
        setActiveTab("tab2");
    };
    const handleTab3 = () => {
        setActiveTab("tab3");
    };
    const handleTab4 = () => {
        setActiveTab("tab4");
    };

    return (
        <div className="Tabs">
            <ul className="nav">
                <li className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Education</li>
                <li className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Career</li>
                <li className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>Strengths</li>
                <li className={activeTab === "tab4" ? "active" : ""} onClick={handleTab4}>Interests</li>
            </ul>

            <div className="outlet">
                {activeTab === "tab1" ? <Eduacation /> : <></>}
                {activeTab === "tab2" ? <Career /> : <></>}
                {activeTab === "tab3" ? <Strength /> : <></>}
                {activeTab === "tab4" ? <Interest /> : <></>}
            </div>
        </div>
    );
};
export default Tabs;