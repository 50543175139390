import React from "react";
// import '../scss/tabContent.scss'
import '../scss/strength.scss'

const Strength = () => {
    return (
        // <div className="FirstTab">
            <div className="content">
                <div className="box">
                <div className="item item-li box-rotate">
                    Time management
                </div>
                <div className="item item-li box-rotate">
                    Honesty and Integrity
                </div>
                <div className="item item-li box-rotate">
                    Hard-working
                </div>
                <div className="item item-li box-rotate">
                    Positive attitudes
                </div>
                <div className="item item-li box-rotate">
                    Reliable and Responsible
                </div>
                <div className="item item-li box-rotate">
                    Nice communication skills
                </div>
                <div className="item item-li box-rotate">
                    Nice customer service skills
                </div>
                <div className="item item-li box-rotate">
                    Learning agility
                </div>
                </div>
                
            </div>
        // </div>
    );
};
export default Strength;