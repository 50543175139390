import React, { useEffect, useState } from "react";
import '../scss/tabContent.scss';

const Interest = () => {
  const [visibleInterests, setVisibleInterests] = useState([]);

  useEffect(() => {
    const timeoutIds = [];

    // Delay the appearance of each interest
    interests.forEach((_, index) => {
      const timeoutId = setTimeout(() => {
        setVisibleInterests((prevInterests) => [...prevInterests, index]);
      }, 500 * (index + 1));

      timeoutIds.push(timeoutId);
    });

    // Clean up the timeouts when the component is unmounted
    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []);

  const interests = [
    "Photography",
    "Travelling",
    "Swimming",
    "Bowling",
    "Challenge",
    "Research"
  ];

  return (
    <div className="FirstTab">
      {interests.map((interest, index) => {
        const isVisible = visibleInterests.includes(index);
        return (
          <div
            key={index}
            className={`interest ${isVisible ? "visible" : ""}`}
          >
            <p>{interest}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Interest;
