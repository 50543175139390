import * as React from 'react';
import '../scss/header.scss'
import Tabs from './Tabs'

export default function About() {
    return (
        <div className='container'>
            <div className='content'>
                <Tabs />
            </div>
        </div>
    )
}
