import { BrowserRouter, Routes, Route, Router } from 'react-router-dom';
import Header from './components/Header'
import Home from './components/Home'
import About from './components/About'
import Project from './components/Project'
import Contact from './components/Contact'
import React, { useState } from 'react';
import './scss/global.scss'

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    //  <div className={`app ${isDarkMode ? 'dark-mode' : ''}`}>
    <>
      <BrowserRouter>
        {/* <Header isDarkMode={isDarkMode} toggleMode={toggleMode} /> */}
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/project" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
    // </div>
  );
}

export default App;
