import '../scss/project.scss'
import * as React from 'react';
import timebank from '../assets/images/timebank.jpg'
import furniture from '../assets/images/furniture.png'
import film from '../assets/images/film.png'
import { Link } from 'react-router-dom';

export default function Project() {
    return (
        <div className='main'>
            <div className='outerbox'>
                <div className='row'>
                    <div className='itemBox'>
                        <Link to='https://woniu-stage1.onrender.com/index.html' target='_blank' alt='timebank'>
                            <div class="box-content">
                                <img src={furniture} alt="furniture" className="box-image" />
                                <div className="box-text">
                                    <h3>Part-time job - Static Furniture Website</h3>
                                    <p>Utilizing by html, css, contributed on static pc website, not responsive</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className='row'>
                    <div className='itemBox'>
                        <Link to='https://woniu-stage2-movie.onrender.com/html/homepage.html' target='_blank' alt='timebank'>
                            <div class="box-content">
                                <img src={film} alt="film" className="box-image" />
                                <div className="box-text">
                                    <h3>Part-time job - Film Website</h3>
                                    <p>Utilizing by html, css, js and axios to fetch data, contributed on pc website, not responsive</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className='row'>
                    <div className='itemBox'>
                        <Link to='https://staging.time-bank.pages.dev/' target='_blank' alt='timebank'>
                            <div class="box-content">
                                <img src={timebank} alt="timebank" className="box-image" />
                                <div className="box-text">
                                    <h3>Studio 6 Project - Timebank Website</h3>
                                    <p>Utilizing by React and Strapi, it's responsive</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                {/* <div className='row'>
                    <div className='itemBox'>
                        <div class="box-content">
                            <img src={timebank} alt="timebank" className="box-image" />
                            <div className="box-text">
                                <h3>Studio 6 Project</h3>
                                <p>Timebank Website</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}